import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import SearchArrow from '../../../../../../assets/images/select-down-arrow.svg';
import SearchUpArrow from '../../../../../../assets/images/select-up-arrow-black.svg';
import css from "../style.scss";
import { v4 as uuidv4 } from 'uuid';

const AutoSearchDropdown = ({ items, placeHolder, onChangeValue , defaultValue , searchDisabled = false, allowNegativeValue = false , isPercent = false, maxLength = null }) => {

  const [searchTerm, setSearchTerm] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState(items || []);
  const [activeIndex, setActiveIndex] = useState(-1); // Track the active dropdown option
  const InputContainerRef = useRef(null);
  const inputRef = useRef(null);
  const optionsRefs = useRef([]); // Store refs for each dropdown option

  useEffect(() => {
    const formatDefaultValue = () => {
      if (defaultValue === undefined || defaultValue === null || defaultValue === '') {
        return '';
      }
      return isPercent && defaultValue !== '0' ? String(defaultValue * 100) : String(defaultValue);
    };

    setSearchTerm(formatDefaultValue());
  }, [defaultValue, isPercent]);

  const handleInputChange = (e) => {
    if (!searchDisabled) {
      let value = allowNegativeValue
        ? e.target.value.replace(/(?!^-)-|[^\d-]/g, '')
        : e.target.value.replace(/\D/g, '');
      if (value === '-0') value = '0';
      setSearchTerm(value);

      const numericValue = isPercent && value ? Number(value) / 100 : value;
      const searchValue = String(numericValue).toLowerCase();
      const filteredSuggestions = items?.filter(item =>
        String(item?.name)?.toLowerCase()?.includes(searchValue)
      );

      //setOptions(filteredSuggestions || []);
      setActiveIndex(-1); // Reset active index when input changes
      setIsFocused(!!filteredSuggestions?.length);
      if (value !== '-') onChangeValue(numericValue);
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleOutsideClick = (e) => {
    if (InputContainerRef.current && !InputContainerRef.current.contains(e.target)) {
      setIsFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const optionSelected = (option) => {
    const displayValue = isPercent ? String(option.value) : option.value;
    setSearchTerm(displayValue);
    setIsFocused(false);
    const normalizedValue = isPercent ? Number(option.value) / 100 : option.value;
    onChangeValue(normalizedValue);
  };

  const handleKeyDown = (e) => {
    if (!isFocused || !options.length) return;

    if (e.key === 'ArrowDown') {
      // Move focus down
      setActiveIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % options.length;
        optionsRefs.current[newIndex]?.scrollIntoView({ block: 'nearest' });
        return newIndex;
      });
      e.preventDefault();
    } else if (e.key === 'ArrowUp') {
      // Move focus up
      setActiveIndex((prevIndex) => {
        const newIndex = (prevIndex - 1 + options.length) % options.length;
        optionsRefs.current[newIndex]?.scrollIntoView({ block: 'nearest' });
        return newIndex;
      });
      e.preventDefault();
    } else if (e.key === 'Enter' && activeIndex >= 0) {
      // Select the active option
      optionSelected(options[activeIndex]);
      e.preventDefault();
    } else if (e.key === 'Tab') {
      // Close the dropdown on Tab
      setIsFocused(false);
    }
    e.stopPropagation();
  };

  const dropdownToggle = (ev) => {
    ev.stopPropagation();
    setIsFocused(!isFocused);

    if (!isFocused && inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={classNames(css.clsSearchBox)} ref={InputContainerRef}>
      <input
        type="text"
        placeholder={placeHolder || 'Select'}
        className={isFocused ? classNames(css.clsSearchInput, css.clsSearchInputActive) : css.clsSearchInput}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        readOnly={searchDisabled}
        id={'newuifilterRef'+ uuidv4()}
        maxLength={maxLength}
      />
      <span className={classNames(css.clsAutoSearchArrow, css.clsAutoSearchRotate)}>
        <ReactSVG
          src={isFocused ? SearchUpArrow : SearchArrow}
          className={css.svgArrowIcon}
          onClick={dropdownToggle}
        />
      </span>

      {isFocused && (
        <div className={css.clsAutoSearchDropdown}>
          <ul className={css.clsAutoSearchList}>
            {options.map((option, index) => (
              <li
                ref={(el) => (optionsRefs.current[index] = el)}
                id={'newuifilterRef' + uuidv4()}
                key={index}
                className={classNames(css.clsAutoSearchOption, {
                  [css.clsActiveOption]: index === activeIndex, // Highlight the active option
                })}
                onClick={(ev) => {
                  ev.stopPropagation();
                  optionSelected(option);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AutoSearchDropdown;
